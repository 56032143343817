<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <a href="/">Inicio</a> / <span class="active">Fiscalías</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Fiscalías</h3>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <b-row cols="12">
        <b-col lg="4">
          <b-card no-body class="card-developer-meetup">
            <div class="bg-light-primary rounded-top text-center">
              <b-img
                :src="require('@/assets/images/imagen-mapa.png')"
                alt="Mapa ubicaciones"
                height="170"
              />
            </div>
            <b-card-body>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-center">
                <b-row>
                  <div class="my-auto">
                    <b-card-title class="mb-25">
                      <h4>Fiscalías Departamentales</h4>
                    </b-card-title>
                    <b-card-text class="mb-0"> </b-card-text>
                  </div>
                </b-row>
              </div>
              <!--/ metting header -->

              <!-- media -->
            </b-card-body>
          </b-card>
          <b-list-group>
            <template v-for="item in itemspadres">
              <b-list-group-item :key="item.id">
                <router-link
                  :to="'/fiscalia/' + item.url"
                  style="text-decoration: none"
                  >{{ item.nombre }}</router-link
                >
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-col>

        <b-col lg="8">
          <div class="mt-2">
            <b-input-group>
              <b-form-input
                @keyup.enter="buscar"
                placeholder="Busqueda por fiscalías"
                v-model="textBusqueda"
              />
              <b-input-group-append>
                <b-button class="btn-fiscalia" @click="buscar">
                  Buscar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-row class="mt-2" v-for="item in itemsBusqueda" :key="item.id">
            <b-col>
              <FiscaliaInfo :fiscaliaData="item" />
            </b-col>
          </b-row>

          <div class="mt-4">
            <l-map
              :zoom.sync="zoom"
              :options="mapOptions"
              :center="center"
              style="height: 500px; width: 100%"
            >
              <l-control-fullscreen
                position="topleft"
                :options="{ title: { false: 'Pantalla Completa!', true: 'Pantalla Normal' } }"
              />
              <l-tile-layer :url="url" :attribution="attribution" />
              <v-marker-cluster
                :options="clusterOptions"
                @clusterclick="click()"
                @ready="ready"
              >
                <l-marker
                  v-for="l in locations"
                  :key="l.id"
                  :lat-lng="l.latlng"
                  :icon="l.icon"
                >
                  <l-popup :content="l.text"></l-popup>
                </l-marker>
              </v-marker-cluster>
            </l-map>
          </div>
        </b-col>
      </b-row>
      <div id="navegacion" class="row mt-5 mb-5"></div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import LControlFullscreen from 'vue2-leaflet-fullscreen';
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import TituloSeccion from "../secciones/TituloSeccion.vue";
import FiscaliaInfo from "../fiscalias/FiscaliaInfo.vue";
import {
  BListGroup,
  BListGroupItem,
  BCol,
  BRow,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { latLng, Icon, icon } from "leaflet";
import "leaflet/dist/leaflet.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BImg,
    BCard,
    BCardBody,
    FiscaliaInfo,
    TituloSeccion,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlFullscreen,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      textBusqueda: "",
      locations: [],
      items: [],
      itemspadres: [],
      itemsBusqueda: [],
      ubicacion: {
        position: { lat: -19.062117883514652, lng: -65.25878906250001 },
        visible: true,
        draggable: false,
      },
      selected: "0",
      zoom: 5,
      center: [-19.062117883514652, -65.25878906250001],
      clusterOptions: {},
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
      },
      icon: icon({
        iconUrl: require("../../assets/images/leaflet/fiscalia-puntero.svg"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  mounted() {
    this.listar();
    setTimeout(() => {
      console.log("done");
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 };
      });
    }, 5000);
  },
  methods: {
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    buscar() {
      if (this.textBusqueda != "") {
        const variable = `?busqueda=${this.textBusqueda}`;
        console.warn(variable);
        axiosIns
          .get(`/front/fiscalias${variable}`)
          .then((res) => {
            this.itemsBusqueda = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.itemsBusqueda = [];
      }
    },
    listar() {
      var url = "/front/fiscalias";
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          this.agregarPosiciones(this.items);
          this.itemspadres = this.items.filter(
            (item) => item.fiscalia_padre_id == null
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    agregarPosiciones(positionArray) {
      positionArray.forEach((element) => {
        this.locations.push({
          id: element.id,
          latlng: latLng(element.latitud, element.longitud),
          icon: this.icon,
          text: `<div>                                      
                <table class="cuadro_info_dentro_mapa">
                    <thead>
                        <tr >
                            <th colspan="2" class="text-center">
                            <h5 class="text-black">${element.nombre}</h5>
                            </th>
                        </tr>
                    </thead>
                    
                    <tbody>                                            
                    <tr>
                        <th scope="row">Fiscal</th>
                        <td>${element.nombre_fiscal}</td>
                    </tr>
                    <tr>
                        <th scope="row">Dirección</th>
                        <td>${element.direccion}</td>
                    </tr>
                    <tr>
                        <th scope="row">Teléfono</th>
                        <td>${element.telefono}</td>
                    </tr>
                    <tr>
                        <th scope="row">Fax</th>
                        <td>${element.fax}</td>
                    </tr>
                                        <tr>
                        
                        <td colspan="2"><a href="https://www.google.com/maps/dir/${element.latitud},${element.longitud}" target="_blank" rel="noopener noreferrer" class="btn small">Como llegar</a></td>
                    </tr>
                    </tbody>
                </table>
                </div>`,
        });
      });
    },
  },
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>