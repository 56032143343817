<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="fiscaliaData.foto_fiscal"
            :text="fiscaliaData.nombre_fiscal"
            size="104px"
            rounded
            class="mb-2"
          />
          <div class="ms-1 d-flex flex-column">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ fiscaliaData.nombre_fiscal }}
              </h4>
              <span class="card-text">{{ fiscaliaData.nombre }}</span>
            </div>
            <div class="flex-wrap d-flex">
              <b-button
                :to="'/fiscalia/' + fiscaliaData.url"
                variant="primary"
              >
                Visitar
              </b-button>
              
             
            </div>
            
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">

          <tr>
            <th class="pb-50">
              <!-- <feather-icon
                icon="CheckIcon"
                class="me-75"
              /> -->
              <span class="font-weight-bold">Ubicación</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ fiscaliaData.ubicacion }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <!-- <feather-icon
                icon="StarIcon"
                class="me-75"
              /> -->
              <span class="font-weight-bold">Dirección</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ fiscaliaData.direccion }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <!-- <feather-icon
                icon="FlagIcon"
                class="me-75"
              /> -->
              <span class="font-weight-bold">Teléfono</span>
            </th>
            <td class="pb-50">
              {{ fiscaliaData.telefono }}
            </td>
          </tr>
          <tr>
            <th>
              <!-- <feather-icon
                icon="PhoneIcon"
                class="me-75"
              /> -->
              <span class="font-weight-bold">Fax</span>
            </th>
            <td>
              {{ fiscaliaData.fax }}
            </td>
          </tr>
          <tr>
            <td>
              <a :href="'https://www.google.com/maps/dir//'+fiscaliaData.latitud+','+fiscaliaData.longitud" target="_blank" rel="noopener noreferrer" class="btn btn-primary">Como llegar</a>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'


export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    fiscaliaData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>